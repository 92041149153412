import type {} from "@mui/lab/themeAugmentation";
import type { PaletteMode } from "@mui/material";
import {
  alpha,
  createTheme,
  lighten,
  type ThemeOptions,
} from "@mui/material/styles";
import { getColor } from "@structured/utils/colors";
import { useEffect, useMemo, useState } from "react";

export const themeOptions: ThemeOptions = {
  typography: {
    fontFamily: [
      "Inter",
      "-apple-system",
      "Segoe UI",
      "Roboto",
      "sans-serif",
    ].join(","),
    fontSize: 16,
    h1: { fontSize: "2.625rem", lineHeight: "3.625rem", fontWeight: 500 },
    h2: { fontSize: "2rem", lineHeight: "2.5rem", fontWeight: 500 },
    h3: { fontSize: "1.5rem", lineHeight: "2.25rem", fontWeight: 500 },
    h4: { fontSize: "1.5rem", lineHeight: "2.25rem" },
    subtitle1: { fontSize: "1.25rem", lineHeight: "1.5rem" },
    subtitle2: { fontSize: "1.125rem", lineHeight: "1.375rem" },
    body1: { fontSize: "1rem", lineHeight: "1.5rem" },
    body2: { fontSize: "0.875rem", lineHeight: "1.375rem", fontWeight: 400 },
    caption: { fontSize: "0.75rem", lineHeight: "1.25rem" },
    button: { textTransform: "none" },
  },
  components: {
    MuiButton: {
      styleOverrides: { root: { borderRadius: "0.5rem", padding: "0.375rem" } },
    },
    MuiCheckbox: {
      styleOverrides: { root: { padding: "0.375rem" } },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: { borderRadius: "0.375rem" },
        input: { padding: "0.75rem 0.875rem" },
        multiline: { padding: 0 },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        grouped: {
          borderRadius: "0.5rem",
          padding: "0.675rem",
          fontSize: "0.875rem",
          flex: 1,
        },
        root: { width: "100%" },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginRight: 0,
          marginLeft: 0,
        },
        labelPlacementTop: {
          gap: "0.375rem",
          alignItems: "start",
        },
      },
    },
    MuiTimelineDot: {
      styleOverrides: {
        root: {
          position: "relative",
          width: "4rem",
          borderRadius: "2rem",
          borderWidth: 0,
          margin: 0,
          boxShadow: "none",
        },
      },
    },
    MuiTimelineItem: {
      styleOverrides: {
        root: {
          ":first-of-type .MuiTimelineConnector-root:has(+ .MuiTimelineDot-root)":
            { background: "none" },
          ":last-of-type .MuiTimelineDot-root + .MuiTimelineConnector-root": {
            background: "none",
          },
        },
      },
    },
    MuiTimelineSeparator: {
      styleOverrides: {
        root: {
          padding: "0 0.5rem",
          borderTopLeftRadius: "1rem",
          borderBottomLeftRadius: "1rem",
        },
      },
    },
    MuiTimelineConnector: {
      styleOverrides: {
        root: {
          width: "3px",
          minHeight: "1rem",
          margin: "0 2rem",
          "&.dashed::after": {
            content: "''",
            width: 0,
            display: "block",
            height: "100%",
            borderRightStyle: "dashed",
            borderRightWidth: "4px",
          },
        },
      },
    },
    MuiTimelineOppositeContent: {
      styleOverrides: { root: { flex: 0.12, padding: "0.5rem" } },
    },
    MuiTimelineContent: {
      styleOverrides: {
        root: { borderTopRightRadius: "1rem", borderBottomRightRadius: "1rem" },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          background: "transparent",
          boxShadow: "none",
          backgroundImage: "none",
        },
      },
    },
    MuiAccordionActions: {
      styleOverrides: {
        root: { padding: 0 },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: { padding: 0 },
        content: {
          margin: 0,
          alignItems: "center",
          "&.Mui-expanded": {
            marginBottom: 0,
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: { root: { padding: 0 } },
    },
    MuiDialog: {
      styleOverrides: { paper: { maxWidth: "100%", borderRadius: "1rem" } },
    },
    MuiDialogActions: {
      styleOverrides: { root: { padding: "1rem 1.5rem" } },
    },
    MuiTabPanel: {
      styleOverrides: { root: { padding: "1rem" } },
    },
  },
};

const getDesignTokens = (mode: PaletteMode, color: string): ThemeOptions => ({
  ...themeOptions,
  components: {
    ...themeOptions.components,
    ...(mode === "light"
      ? {
        MuiToggleButton: {
          styleOverrides: {
            root: {
              borderRadius: "0.375rem",
              padding: "0.475rem",
              border: "1px solid rgba(0, 0, 0, 0.23)",
            },
          },
        },
      }
      : {
        MuiToggleButton: {
          styleOverrides: {
            root: {
              borderRadius: "0.375rem",
              padding: "0.475rem",
              border: "1px solid rgba(255, 255, 255, 0.23)",
            },
          },
        },
      }),
  },
  palette: {
    mode,
    contrastThreshold: 1.9,
    ...(mode === "light"
      ? {
        background: {
          default: "#FFFFFF",
        },
        text: {
          primary: "#141414",
          secondary: "#858585",
        },
        primary: {
          main: color,
          light: alpha(color, 0.2),
        },
        secondary: {
          main: lighten(color, 0.2),
          light: alpha(lighten(color, 0.2), 0.2),
        },
        night: {
          main: "#6185A8",
          light: alpha("#6185A8", 0.2),
        },
        day: {
          main: "#F88E87",
          light: alpha("#F88E87", 0.2),
        },
        dawn: {
          main: "#FF9E74",
          light: alpha("#FF9E74", 0.2),
        },
        sunshine: {
          main: "#EAAA00",
          light: alpha("#EAAA00", 0.2),
        },
        nature: {
          main: "#91BF6C",
          light: alpha("#91BF6C", 0.2),
        },
        forest: {
          main: "#228564",
          light: alpha("#228564", 0.2),
        },
        twilight: {
          main: "#974767",
          light: alpha("#974767", 0.2),
        },
        midnight: {
          main: "#2C5073",
          light: alpha("#2C5073", 0.2),
        },
        classic: {
          main: "#141414",
          light: alpha("#141414", 0.2),
        },
        divider: "#EBEBEB",
      }
      : {
        background: {
          default: "#242424",
        },
        text: {
          primary: "#FFFFFF",
          secondary: "#858585",
        },
        primary: {
          main: color,
          light: alpha(color, 0.2),
        },
        secondary: {
          main: lighten(color, 0.2),
          light: alpha(lighten(color, 0.2), 0.2),
        },
        night: {
          main: "#5E96CB",
          light: alpha("#5E96CB", 0.2),
        },
        day: {
          main: "#F88E87",
          light: alpha("#F88E87", 0.2),
        },
        dawn: {
          main: "#F27C49",
          light: alpha("#F27C49", 0.2),
        },
        sunshine: {
          main: "#FFC630",
          light: alpha("#FFC630", 0.2),
        },
        nature: {
          main: "#2F9E66",
          light: alpha("#2F9E66", 0.2),
        },
        forest: {
          main: "#007F5C",
          light: alpha("#007F5C", 0.2),
        },
        twilight: {
          main: "#B85C7A",
          light: alpha("#B85C7A", 0.2),
        },
        midnight: {
          main: "#254E79",
          light: alpha("#254E79", 0.2),
        },
        classic: {
          main: "#FFFFFF",
          light: alpha("#FFFFFF", 0.2),
          contrastText: "#000000",
        },
        divider: "rgba(255, 255, 255, 0.23)",
      }),
  },
});

export const useCustomTheme = (
  initialThemeMode: PaletteMode,
  initialThemeColor: string
) => {
  const [mode, setMode] = useState<PaletteMode>(initialThemeMode);
  const [color, setColor] = useState<string>(initialThemeColor);

  const handlers = useMemo(
    () => ({
      setThemeMode: (mode: PaletteMode) => {
        setMode(mode);
      },
      setThemeColor: (color: string) => {
        if (color) {
          setColor(color);
        }
      },
    }),
    []
  );

  const theme = useMemo(
    () => createTheme(getDesignTokens(mode, getColor(color, mode))),
    [mode, color]
  );

  return { theme, handlers };
};
