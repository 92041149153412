import {
    createAsyncThunk,
    createSlice,
    type PayloadAction,
} from "@reduxjs/toolkit";
import { type CustomerInfo, Purchases } from "@revenuecat/purchases-js";

interface IsProState {
    isPro: boolean;
    customerInfo: CustomerInfo | null;
    loading: "idle" | "pending" | "succeeded" | "failed";
    error: string | null;
}

const initialState: IsProState = {
    isPro: false,
    customerInfo: null,
    loading: "idle",
    error: null,
};

export const fetchIsPro = createAsyncThunk<
    { isPro: boolean; customerInfo: CustomerInfo | null },
    string,
    { rejectValue: boolean }
>(
    "isPro/fetchIsPro",
    async (appUserId: string, { rejectWithValue }) => {
        try {
            const purchases = Purchases.configure(
                process.env.REACT_APP_REVENUECAT_KEY,
                appUserId,
            );

            const isEntitled = await purchases.isEntitledTo("pro");
            const customerInfo = await purchases.getCustomerInfo();
            return { isPro: isEntitled, customerInfo };
        } catch (error) {
            console.error("Error fetching pro status:", error);
            return rejectWithValue(false);
        }
    },
);

const isProSlice = createSlice({
    name: "isPro",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchIsPro.pending, (state) => {
                state.loading = "pending";
            })
            .addCase(
                fetchIsPro.fulfilled,
                (state, action: PayloadAction<{ isPro: boolean; customerInfo: CustomerInfo | null }>) => {
                    state.loading = "succeeded";
                    state.isPro = action.payload.isPro;
                    state.customerInfo = action.payload.customerInfo;
                    state.error = null;
                },
            )
            .addCase(fetchIsPro.rejected, (state) => {
                state.loading = "failed";
                state.isPro = false;
                state.customerInfo = null;
                state.error = "Failed to fetch pro status";
            });
    },
});

export default isProSlice.reducer;
