import { generateTimelineEntries, type TimelineEntry } from "../timeline";
import { useDailyRecurringTasks } from "./useDailyRecurringTasks";
import { useDailyTasks } from "./useDailyTasks";

export const useDailyTimelineEntries = (
  date: Date,
  currentDate: Date
): TimelineEntry[] => {
  const tasks = useDailyTasks(date);
  const recurringTasks = useDailyRecurringTasks(date);
  return generateTimelineEntries(
    [...tasks, ...recurringTasks],
    date,
    currentDate
  );
};