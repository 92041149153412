const getBackgroundColorWithOpacity = (rgba: string, opacity: number) => {
    if (rgba.includes("rgba")) {
        const red = rgba.split(",")[0].split("(")[1];
        const green = rgba.split(",")[1];
        const blue = rgba.split(",")[2];
        return `rgba(${red}, ${green}, ${blue}, ${opacity})`;
    }
    const red = rgba.split(",")[0].split("(")[1];
    const green = rgba.split(",")[1];
    const blue = rgba.split(",")[2].split(")")[0];
    return `rgba(${red}, ${green}, ${blue}, ${opacity})`;

}

export default getBackgroundColorWithOpacity;