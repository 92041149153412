import { Box, Button, CircularProgress, Typography, useTheme } from "@mui/material";
import logo from "@structured/assets/signup-page-images/logo.webp";
import { useSettings } from "@structured/store";
import { getColor } from "@structured/utils/colors";
import { useEffect, useState } from "react";
import Confetti from "react-confetti";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchIsPro } from "src/redux/slices/isProSlice";
import { type AppDispatch, type RootState } from "src/redux/store";

import NotFound from "../NotFound";

const MAX_RETRIES = 5;
const RETRY_DELAY = 2000;
const STATIC_COLORS = [
    "day",
    "night",
    "nature",
    "classic",
    "dawn",
    "sunshine",
    "forest",
    "twilight",
    "midnight",
];

export default function SuccessPayment() {
    const dispatch = useDispatch<AppDispatch>();
    const { isPro, loading, error } = useSelector((state: RootState) => state.isPro);
    const [retryCount, setRetryCount] = useState(0);
    const { settings } = useSettings();

    const [showConfetti, setShowConfetti] = useState(false);
    const theme = useTheme();

    useEffect(() => {
        const appUserId = settings.analytics_id;
        const processProStatus = async () => {
            await dispatch(fetchIsPro(appUserId.toUpperCase()));
            if (!isPro && retryCount < MAX_RETRIES) {
                setTimeout(() => {
                    setRetryCount(prevCount => prevCount + 1);
                }, RETRY_DELAY);
            } else if (isPro) {
                setShowConfetti(true);
            }
        };
        void processProStatus();
    }, [dispatch, settings.analytics_id, isPro, retryCount]);

    if (loading === "pending" || (!isPro && retryCount < MAX_RETRIES)) {
        return (
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
                <CircularProgress />
                <Typography variant="body1" mt={2}>
                    Verifying your Pro status...
                </Typography>
                <Typography variant="body2" color="text.secondary" mt={1}>
                    This may take a few moments.
                </Typography>
            </Box>
        );
    }

    if (isPro) {
        return (
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh" position="relative">
                {showConfetti && (
                    <Confetti
                        style={{ position: "absolute", top: 0, left: 0, zIndex: 0 }}
                        colors={STATIC_COLORS.map(color => getColor(color, theme.palette.mode))}
                        opacity={0.75}
                    />
                )}
                <Box display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"} zIndex={1}>
                    <Box component="img" src={logo} alt="Structured Logo" width={"3.75rem"} height={"3.75rem"} />
                    <Typography variant="h4" mt={2} textAlign="center">
                        You&apos;re on
                    </Typography>
                    <Typography variant="h4" textAlign="center" fontWeight={500}>
                        Structured Pro
                    </Typography>
                </Box>
                <Box mt={2}>
                    <Link to="/">
                        <Button variant="contained" disableElevation>
                            Go back to Home
                        </Button>
                    </Link>
                </Box>
            </Box>
        );
    }

    return (
        <NotFound />
    );
}
