import "./App.scss";

import { Box, CircularProgress } from "@mui/material";
import * as Sentry from "@sentry/react";
import { supabase } from "@structured/utils/api";
import { startReplicationForAll, useDb } from "@structured/utils/rxdb";
import { useCallback, useEffect, useState } from "react";
import { RouterProvider } from "react-router-dom";
import { combineLatest, map, type Subscription } from "rxjs";

import { router } from "./router/router";
import { SentryUserContext } from "./sentry/sentry-user-context";
import { OccurrenceProvider, RecurringProvider, TaskProvider, useSettings } from "./store";
import { TelemetryProvider } from "./telemetry";
import { useReplicationSyncChecker } from "./utils/hooks";
import { Login, TermsOfService } from "./views/Auth";

function App() {
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSyncing, setIsSyncing] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [userId, setUserId] = useState(null);
  const [termsAcceptedTrigger, setTermsAcceptedTrigger] = useState(false);

  const { settings, isLoading, initSettings } = useSettings();
  const { db } = useDb();

  const refreshSettings = useCallback(async () => {
    await initSettings();
    setShowAlert(false);
  }, [initSettings]);

  useEffect(() => {
    const supabaseFunction = () => {
      const { data } = supabase.auth.onAuthStateChange((event, session) => {
        if (session !== null) {
          const userId = session?.user.id;
          setUserId(userId);
          setSession(session);
          setLoading(false);
        } else {
          setSession(null);
          setLoading(false);
        }
      });
    };

    if (isLoading) return;

    supabaseFunction();
  }, [isLoading]);

  useEffect(() => {
    if (loading || isLoading || !session) return;

    const handleReplication = async () => {
      try {
        await initSettings();

        if (!settings) {
          const replicationStates = await startReplicationForAll(db, userId as string);

          if (!replicationStates) throw new Error("No replication states created");

          const replicationState: Subscription = combineLatest(
            replicationStates.map((rs) => rs.active$)
          )
            .pipe(map((isActiveStates) => isActiveStates.includes(true)))
            .subscribe((syncing) => {
              setIsSyncing(syncing);
            });

          return () => replicationState.unsubscribe();
        }
      } catch (error) {
        console.error("Error in handleReplication:", error);
      }
    };

    void handleReplication();
  }, [loading, session, isLoading, settings]);

  useEffect(() => {
    if (termsAcceptedTrigger) {
      void refreshSettings();
    }
  }, [termsAcceptedTrigger, refreshSettings]);

  useEffect(() => {
    if (session && settings && !settings.cloud_terms_date) {
      setShowAlert(true);
    }
  }, [session, settings]);

  return (
    <>
      {loading || isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      ) : session ? (
        <>
          {showAlert ? (
            <>
              <TaskProvider>
                <OccurrenceProvider>
                  <RecurringProvider>
                    <TermsOfService setTermsAcceptedTrigger={setTermsAcceptedTrigger} />
                  </RecurringProvider>
                </OccurrenceProvider>
              </TaskProvider>
            </>
          ) : (
            <>
              <Sentry.ErrorBoundary fallback={<>An error has occurred</>}>
                <SentryUserContext />
                <TelemetryProvider>
                  <Box className="App">
                    <RouterProvider router={router} />
                  </Box>
                </TelemetryProvider>
              </Sentry.ErrorBoundary>
            </>
          )}
        </>
      ) : (
        <Box className="App">
          <Login />
        </Box>
      )}
    </>
  );
}

export default App;
