import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const NotFound: React.FC = () => {
    const navigate = useNavigate();

    return (
        <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            height={"100vh"}
            textAlign={"center"}
            sx={{ backgroundColor: (theme) => theme.palette.background.default, color: (theme) => theme.palette.text.primary, }}
        >
            <Typography variant="h1" gutterBottom>
                404
            </Typography>
            <Typography variant="h5" component={"h2"} gutterBottom>
                The page you are looking for does not exist.
            </Typography>
            <Button
                size="large"
                variant="contained"
                color="primary"
                onClick={() => navigate("/")}
            >
                Go to Home
            </Button>
        </Box>
    );
};

export default NotFound;