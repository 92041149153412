import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import ContentCopy from "@mui/icons-material/ContentCopy";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { Box, Button, Drawer, Fade, IconButton } from "@mui/material";
import { createTheme, type Theme, ThemeProvider } from "@mui/material";
import { ConfirmationModal, DottedDivider, ModalWrapper } from "@structured/components";
import { type Task } from "@structured/store";
import { getColor } from "@structured/utils/colors";
import { type RxRecurringNaked } from "@structured/utils/rxdb";
import { type RecurringOwnFields } from "@structured/utils/tasks";
import dayjs from "dayjs";
import { useCallback, useState } from "react";

import { RecurringUpdatePromptModal } from "../RecurringUpdatePromptModal/RecurringUpdatePromptModal";
import { TaskDrawerHeader } from "../TaskForm/Components/TaskDrawerHeader";
import { TaskForm } from "../TaskForm/TaskForm";
import { useTaskEditModal } from "./useTaskEditModal";

export const TaskEditModal = (props: {
  title?: string;
  onCancel: () => void;
  onSubmit: () => void;
  onCopy?: () => void;
  onDelete?: () => void;
  taskOrRecurring: Task | RxRecurringNaked;
  taskDay: Date;
  setRecurringConfig?: (cfg: RecurringOwnFields) => void;
}) => {
  const {
    showDayChangedWarning,
    showRecurringUpdatePromptModal,
    isCalendarTask,
    newRecurringOwnFields,
    tmpTask,
    tmpSubtasks,
    completedAt,
    confirmDayChange,
    setShowDayChangedWarning,
    setIsShowRecurringUpdateModal,
    setNewRecurringOwnFields,
    updateTmpTask,
    setTmpSubtasks,
    updateAllRecurring,
    afterTaskEditSubmit,
    updateSingleRecurringInstance,
    updateFutureRecurring,
    updateCompletedAt,
    completeSubtask,
  } = useTaskEditModal({
    taskDay: props.taskDay,
    taskOrRecurring: props.taskOrRecurring,
    onSubmit: props.onSubmit,
  });

  const [open, setOpen] = useState(true);
  const [timeZone, setTimeZone] = useState<string | null>(props.taskOrRecurring.timezone);

  const handleClose = () => {
    setOpen(false);
    props.onCancel();
  };

  const createDynamicTheme = useCallback(
    (theme: Theme) =>
      createTheme({
        ...theme,
        palette: {
          ...theme.palette,
          primary: { main: getColor(tmpTask.color, theme.palette.mode) },
        },
      }),
    [tmpTask.color]
  );

  return (
    <ThemeProvider theme={createDynamicTheme}>
      <Fade in={open}>
        <Box
          onClick={handleClose}
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            bgcolor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1200,
          }}
        />
      </Fade>
      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiDrawer-paper": {
            height: "calc(100% - 3rem)",
            boxSizing: "border-box",
            right: "1.5rem",
            top: "1.5rem",
            bottom: "1.5rem",
            width: "37rem",
            borderRadius: "1rem",
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <TaskDrawerHeader focusText="Edit" onClose={handleClose} />
        <DottedDivider />
        <Box sx={{
          flexGrow: 1,
          overflowY: "auto",
        }}>
          {showRecurringUpdatePromptModal && (
            <RecurringUpdatePromptModal
              onClose={() => setIsShowRecurringUpdateModal(false)}
              onUpdateAll={() => updateAllRecurring()}
              onUpdateSingle={() => updateSingleRecurringInstance()}
              onUpdateFuture={() => updateFutureRecurring()}
            />
          )}
          <ConfirmationModal
            open={showDayChangedWarning}
            onCancel={() => setShowDayChangedWarning(false)}
            onConfirm={() => confirmDayChange()}
            description="Only this task will be updated. Other occurrences are unaffected"
          />

          <TaskForm
            isEdit={true}
            disabled={isCalendarTask}
            tmpTask={tmpTask}
            recurringCfg={newRecurringOwnFields}
            tmpSubtasks={tmpSubtasks}
            onSubmit={(starTime: number) => afterTaskEditSubmit(starTime)}
            onUpdateTmpTask={updateTmpTask}
            onUpdateTmpSubtasks={setTmpSubtasks}
            onUpdateRecurringCfg={(cfg) =>
              setNewRecurringOwnFields((val) => ({ ...val, ...cfg }))
            }
            onCompleteTmpSubtask={completeSubtask}
            timeZone={timeZone}
            setTimeZone={setTimeZone}
          />
        </Box>

        <Box sx={{
          zIndex: 1,
          position: "sticky",
          bottom: 0,
          marginTop: "auto",
        }}>
          <DottedDivider />
          <Box sx={{
            padding: "1.5rem",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton onClick={props.onDelete}>
                <DeleteOutlinedIcon />
              </IconButton>
              <IconButton onClick={() => {
                props.onCopy();
                if (props.setRecurringConfig !== undefined || newRecurringOwnFields !== null) {
                  props.setRecurringConfig(newRecurringOwnFields);
                }
              }}>
                <ContentCopy sx={{
                  width: "1.5rem",
                  height: "1.5rem",
                }} />
              </IconButton>
              <IconButton onClick={updateCompletedAt}>
                {!completedAt ? (<CircleOutlinedIcon color="primary" />) : (<CheckCircleIcon color="primary" />)}
              </IconButton>
            </Box>
            <Button
              disableElevation
              variant="contained"
              onClick={() => afterTaskEditSubmit(tmpTask.start_time === null ? dayjs().hour() : tmpTask.start_time, completedAt !== null, timeZone === "floating" ? null : timeZone)}
              sx={{ padding: "0.5rem 1rem" }}
            >
              Update Task
            </Button>
          </Box>
        </Box>
      </Drawer>
    </ThemeProvider>
  );
};