import { alpha, darken, type Theme } from "@mui/material/styles";

import type { TaskColor, ThemeVariant } from "../colors.types";
import { validateHex } from "./validateHex";

export const getThemeColor = (
  color: TaskColor | string,
  theme: Theme,
  variant: ThemeVariant = "main"
): string => {
  const hexColor = validateHex(color, theme.palette.mode);
  switch (color) {
    case "day":
    case "night":
    case "nature":
    case "classic":
    case "dawn":
    case "sunshine":
    case "forest":
    case "twilight":
    case "midnight": 
      return theme.palette[color][variant];

    default:
      switch (variant) {
        case "main":
          return hexColor;
        case "light":
          return alpha(hexColor, 0.2);
        case "dark":
          return darken(hexColor, 0.3);
        case "contrastText":
          return theme.palette.getContrastText(hexColor);
      }
  }
};
