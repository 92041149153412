import "@fontsource/inter";
import "@fontsource/inter/500.css";
import "@fontsource/inter/700.css";
import "./styles/index.scss";
import "./fonts/MaterialIconsRounded.woff2";
import "./sentry/sentry";

import CssBaseline from "@mui/material/CssBaseline";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";

import App from "./App";
import store from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import { SessionProvider, SettingsProvider, UIProvider } from "./store";
import { CustomThemeProvider } from "./theme";
import { DatabaseProvider } from "./utils/rxdb";

const container = document.getElementById("root");
const root = createRoot(container);
dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(timezone);

root.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatabaseProvider>
        <UIProvider>
          <SessionProvider>
            <SettingsProvider>
              <CustomThemeProvider>
                <CssBaseline enableColorScheme />
                <Provider store={store}>
                  <App />
                </Provider>
              </CustomThemeProvider>
            </SettingsProvider>
          </SessionProvider>
        </UIProvider>
      </DatabaseProvider>
    </LocalizationProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
