import * as Sentry from "@sentry/react";
import GitInfo from "react-git-info/macro";

const gitInfo = GitInfo();

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  enabled: process.env.REACT_APP_SENTRY_ENABLED === "true",
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  tracePropagationTargets:
    process.env.REACT_APP_SENTRY_TRACING_ORIGINS.split(","),
  ignoreErrors: ["ResizeObserver loop limit exceeded"],
  autoSessionTracking: true,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  tracesSampleRate: 1.0,
  debug: process.env.NODE_ENV === "development",
  release: gitInfo.commit.shortHash
});
