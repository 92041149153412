import { Close as CloseIcon, Help as HelpIcon, KeyboardArrowDown, NearMe as NearMeIcon, PublicOutlined as PublicOutlinedIcon } from "@mui/icons-material";
import { Box, IconButton, List, ListItem, ListItemButton, Popover, TextField, Tooltip, Typography, useTheme } from "@mui/material";
import getBackgroundColorWithOpacity from "@structured/utils/colors/functions/getBackgroundColorWithOpacity";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { type UseFormSetValue } from "react-hook-form";
import { TIMEZONES } from "src/utils/timezones";

import { type FormValues } from "../TaskForm";

interface TimeZoneSelectorProps {
    disabled: boolean;
    time: string;
    timeZone: string | null;
    setTimezone: (timezone: string) => void;
    setValue: UseFormSetValue<FormValues>;
    toolTipText: string;
    taskDuration: number;

}

const TimeZoneSelector = (props: TimeZoneSelectorProps) => {
    const theme = useTheme();
    const timeZones = TIMEZONES;
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [toggleDropdown, setToggleDropdown] = useState(false);
    const [selectedTimeZone, setSelectedTimeZone] = useState<string | null>(null);
    const searchInputRef = useRef<HTMLInputElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setSearchTerm("");
        setToggleDropdown(false);
        setSelectedTimeZone(null);
        setAnchorEl(null);
    };

    const handleSearchClick = () => {
        setToggleDropdown(true);
        setTimeout(() => {
            searchInputRef.current?.focus();
        }, 0);
    };

    const handleClearSearch = () => {
        setSearchTerm("");
        if (searchInputRef.current) {
            searchInputRef.current.focus();
        }
    };
    const floatingTimeZoneLabel = "Floating Timezone";
    const filteredTimeZones = timeZones.filter((tz) =>
        tz.city.toLowerCase().includes(searchTerm.toLowerCase()) ||
        tz.country.toLowerCase().includes(searchTerm.toLowerCase()) ||
        tz.identifier.toLowerCase().includes(searchTerm.toLowerCase())
    );


    const open = Boolean(anchorEl);
    const id = open ? "time-zone-popover" : undefined;
    const localTimeZone = dayjs.tz.guess();

    const convertTimeToTimeZone = (time: string, timeZone: string | null) => {
        const effectiveTimeZone = getEffectiveTimeZone(timeZone);
        return dayjs(time).tz(effectiveTimeZone).format("h:mm A");
    };

    const getTimeZoneAbbreviation = (timeZone: string | null) => {
        const effectiveTimeZone = getEffectiveTimeZone(timeZone);
        return dayjs().tz(effectiveTimeZone).format("z");
    }

    const getEffectiveTimeZone = (timeZone: string | null) => {
        return timeZone === null || timeZone === "floating" ? dayjs.tz.guess() : timeZone;
    };

    const getTimeZoneLabel = (timeZone: string | null) => {
        if (timeZone === null || timeZone === "floating") {
            return floatingTimeZoneLabel;
        }

        const timeZoneItem = timeZones.find((tz) => tz.identifier === timeZone);
        if (timeZoneItem) {
            return `${timeZoneItem.city}, ${timeZoneItem.country}`;
        }
        return "";
    }

    useEffect(() => {
        if (selectedTimeZone) {
            props.setTimezone(selectedTimeZone);
            handleClose();
        }
    }, [selectedTimeZone]);

    const renderTimeZoneList = () => {
        if (filteredTimeZones.length === 0) {
            return <Typography sx={{ p: 1.5 }}>No results found</Typography>;
        }

        const floatingTimezone = "floating";
        const selectedTimezone = props.timeZone;

        const selectedTimeZoneItem = filteredTimeZones.find((tz) => tz.identifier === selectedTimezone);
        const remainingTimeZones = filteredTimeZones.filter((tz) => tz.identifier !== selectedTimezone);
        return (
            <>
                {(searchTerm.toLowerCase() === "floating" || searchTerm === "") && (
                    <ListItem
                        onClick={() => { setSelectedTimeZone(floatingTimezone) }}
                        sx={{
                            backgroundColor: (selectedTimezone === floatingTimezone || selectedTimezone === null)
                                ? getBackgroundColorWithOpacity(theme.palette.primary.light, 0.4)
                                : theme.palette.background.paper,
                            "&:hover": {
                                backgroundColor: (selectedTimezone === floatingTimezone || selectedTimezone === null)
                                    ? null
                                    : theme.palette.action.hover,
                            },
                        }}
                    >
                        <ListItemButton
                            disableRipple
                            sx={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}
                            style={{ backgroundColor: "transparent" }}
                        >
                            <Box display="flex" alignItems="center">
                                <Typography
                                    fontSize={"1rem"}
                                    fontWeight={400}
                                    lineHeight={"1.5rem"}
                                    color={theme.palette.text.primary}
                                >
                                    Floating Timezone
                                </Typography>
                                <Box component={"span"} display={"flex"} justifyContent={"space-between"} alignItems={"center"} gap={"0.125rem"}>
                                    <Typography
                                        fontSize={"0.75rem"}
                                        fontWeight={400}
                                        lineHeight={"1.25rem"}
                                        textAlign={"left"}
                                        color={theme.palette.text.secondary}
                                        marginLeft={"0.5rem"}
                                    >
                                        {getTimeZoneAbbreviation(floatingTimezone)}
                                    </Typography>

                                    <NearMeIcon
                                        sx={{
                                            height: "0.875rem",
                                            width: "0.875rem",
                                            color: theme.palette.text.secondary,
                                        }}
                                    />
                                </Box>
                            </Box>
                            <Tooltip title="Adjusts to your local timezone" placement="top">
                                <HelpIcon sx={{ width: "1.25rem", height: "1.25rem", color: theme.palette.text.secondary }} />
                            </Tooltip>
                        </ListItemButton>
                    </ListItem>
                )}

                {selectedTimeZoneItem && (
                    <ListItem
                        onClick={() => { setSelectedTimeZone(selectedTimeZoneItem.identifier) }}
                        sx={{
                            backgroundColor: selectedTimezone === selectedTimeZoneItem.identifier ? getBackgroundColorWithOpacity(theme.palette.primary.light, 0.4) : theme.palette.background.paper,
                            "&:hover": { backgroundColor: selectedTimezone === selectedTimeZoneItem.identifier ? null : theme.palette.action.hover, },
                        }}
                    >
                        <ListItemButton
                            disableRipple
                            sx={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}
                            style={{ backgroundColor: "transparent" }}
                        >
                            <Box display="flex" alignItems="center">
                                <Typography
                                    fontSize={"1rem"}
                                    fontWeight={400}
                                    lineHeight={"1.5rem"}
                                    color={theme.palette.text.primary}
                                >
                                    {selectedTimeZoneItem.city},{" "}
                                    {selectedTimeZoneItem.country}
                                </Typography>
                                <Typography
                                    fontSize={"0.75rem"}
                                    fontWeight={400}
                                    lineHeight={"1.25rem"}
                                    textAlign={"left"}
                                    color={theme.palette.text.secondary}
                                    marginLeft={"0.5rem"}
                                >
                                    {getTimeZoneAbbreviation(selectedTimeZoneItem.identifier)}
                                </Typography>
                            </Box>
                            <Typography sx={{
                                fontSize: "0.875rem",
                                fontWeight: 500,
                                lineHeight: "1.375rem",
                                color: theme.palette.text.secondary,
                            }}>
                                {convertTimeToTimeZone(props.time, selectedTimeZoneItem.identifier)}
                            </Typography>
                        </ListItemButton>
                    </ListItem>
                )}

                {remainingTimeZones.map((tz, index) => (
                    <ListItem
                        key={index}
                        onClick={() => { setSelectedTimeZone(tz.identifier) }}
                        sx={{
                            backgroundColor: theme.palette.background.paper,
                            "&:hover": {
                                backgroundColor: theme.palette.action.hover,
                            },
                        }}
                    >
                        <ListItemButton
                            disableRipple
                            style={{ backgroundColor: "transparent" }}
                            sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
                        >
                            <Box display="flex" alignItems="center" gap={"0.35rem"}>
                                <Typography
                                    fontSize={"1rem"}
                                    fontWeight={400}
                                    lineHeight={"1.5rem"}
                                    color={theme.palette.text.primary}
                                >
                                    {tz.city.length > 16 ? tz.city.substring(0, 16) + "..." : tz.city},{" "}
                                    {tz.country.length > 16 ? tz.country.substring(0, 16) + "..." : tz.country}
                                </Typography>
                                <Typography
                                    fontSize={"0.75rem"}
                                    fontWeight={400}
                                    lineHeight={"1.25rem"}
                                    textAlign={"left"}
                                    color={theme.palette.text.secondary}
                                    marginLeft={"0.5rem"}
                                >
                                    {getTimeZoneAbbreviation(tz.identifier)}
                                </Typography>
                                {tz.identifier === localTimeZone && (
                                    <NearMeIcon sx={{ height: "0.875rem", width: "0.875rem", color: theme.palette.text.secondary, }} />
                                )}
                            </Box>
                            <Box display="flex" alignItems="center">
                                <Typography sx={{
                                    fontSize: "0.875rem",
                                    fontWeight: 500,
                                    lineHeight: "1.375rem",
                                    color: theme.palette.text.secondary,
                                }}>
                                    {convertTimeToTimeZone(props.time, tz.identifier)}
                                </Typography>
                            </Box>
                        </ListItemButton>
                    </ListItem>
                ))}
            </>
        );
    };


    return (
        <>
            <Tooltip
                PopperProps={{
                    modifiers: [
                        {
                            name: "offset",
                            options: {
                                offset: [0, -20],
                            },
                        },
                    ],
                }}
                placement="top"
                title={
                    <Box component="span" sx={{ display: "flex", alignItems: "center", gap: "0.25rem", }}>
                        <NearMeIcon sx={{ width: "0.75rem", height: "0.75rem", color: "white", marginTop: "0.15rem" }} />
                        <span dangerouslySetInnerHTML={{ __html: props.toolTipText }} />
                    </Box>
                }
            >
                <IconButton
                    sx={{ display: "flex", height: "3.125rem" }}
                    disableRipple
                    disabled={props.disabled}
                    aria-describedby={id}
                    onClick={handleClick}
                >
                    <PublicOutlinedIcon sx={{ color: "secondary", height: "1.25rem" }} />
                    {props.timeZone !== null && props.timeZone !== "floating" && (
                        <Box component={"span"}>
                            <Typography fontSize={"1rem"} lineHeight={"1.5rem"} textAlign={"left"} color={theme.palette.text.secondary} marginLeft={"0.25rem"}>
                                {getTimeZoneAbbreviation(props.timeZone)}
                            </Typography>
                        </Box>
                    )}
                </IconButton>
            </Tooltip>
            <Popover
                sx={{ top: "-0.75rem", }}
                disableScrollLock
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                PaperProps={{
                    style: {
                        backgroundColor: "transparent",
                        backgroundImage: "none",
                        boxShadow: "none",
                        overflow: "hidden",
                    },
                }}
            >
                <Box component={"span"} p={2}>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            padding: "0.75rem",
                            alignItems: "flex-start",
                            borderRadius: "0.5rem",
                            border: `0.0625rem solid ${theme.palette.grey[300]}`,
                            background: theme.palette.background.paper,
                            boxShadow: `0rem 0.125rem 0.1875rem 0rem ${theme.palette.grey[300]}`,
                        }}
                    >
                        <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            width={"100%"}
                            marginBottom={"0.5rem"}
                        >
                            <Typography gutterBottom>Select a Time Zone</Typography>
                            <IconButton onClick={handleClose}>
                                <CloseIcon sx={{ height: "1.25rem" }} />
                            </IconButton>
                        </Box>
                        {(toggleDropdown) ? (
                            <TextField
                                InputProps={{
                                    endAdornment: (
                                        <>
                                            {searchTerm.length > 0 && (
                                                <IconButton onClick={handleClearSearch} disableRipple>
                                                    <CloseIcon sx={{ width: "1rem", height: "1rem" }} />
                                                </IconButton>
                                            )}
                                        </>
                                    )
                                }}
                                inputRef={searchInputRef}
                                autoComplete="off"
                                onFocus={() => setToggleDropdown(true)}
                                fullWidth
                                variant="outlined"
                                placeholder="Search Time Zones..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                sx={{ mb: 2 }}
                            />
                        ) : (
                            <Box
                                display={"flex"}
                                flexDirection={"row"}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                                width={"26rem"}
                                height={"3.125rem"}
                                padding={"0.75rem 1rem"}
                                gap={"0.5rem"}
                                borderRadius={"0.5rem"}
                                border={`0.0625rem solid ${theme.palette.grey[300]}`}
                                onClick={() => handleSearchClick()}
                            >
                                <Typography
                                    fontSize={"0.75rem"}
                                    fontWeight={400}
                                    lineHeight={"1.25rem"}
                                    textAlign={"left"}
                                    color={theme.palette.text.secondary}
                                    marginLeft={"0.5rem"}
                                >
                                    <Box
                                        component={"span"}
                                        overflow={"hidden"}
                                        color={theme.palette.text.primary}
                                        fontSize={"1rem"}
                                        fontWeight={400}
                                        lineHeight={"1.5rem"}
                                    >
                                        {getTimeZoneLabel(props.timeZone)}
                                    </Box>
                                    <Box
                                        component={"span"}
                                        overflow={"hidden"}
                                        color={theme.palette.text.secondary}
                                        fontSize={"0.75rem"}
                                        fontWeight={400}
                                        lineHeight={"1.25rem"}
                                        textAlign={"left"}
                                        marginLeft={"0.5rem"}
                                    >
                                        {getTimeZoneAbbreviation(props.timeZone)}
                                    </Box>
                                    {props.timeZone === dayjs.tz.guess() && (<NearMeIcon sx={{ width: "1.25rem", height: "1.25rem", paddingLeft: "0.25rem", paddingTop: "0.625rem" }} />)}
                                </Typography>
                                <KeyboardArrowDown sx={{ width: "1.5rem", height: "1.5rem", }} />
                            </Box>
                        )}
                    </Box>
                    <List
                        sx={{
                            visibility: toggleDropdown ? "visible" : "hidden",
                            maxHeight: "18.75rem",
                            overflow: "auto",
                            marginRight: "1rem",
                            marginLeft: "1rem",
                            position: "relative",
                            marginTop: "-1.25rem",
                            display: "flex",
                            width: "26rem",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            borderRadius: "0.5rem",
                            boxShadow: `0rem 0.125rem 0.1875rem 0rem ${theme.palette.grey[300]}`,
                            background: theme.palette.background.paper,
                        }}
                    >
                        {renderTimeZoneList()}
                    </List>
                </Box>
            </Popover>
        </>
    );
};

export default TimeZoneSelector;