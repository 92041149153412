import {
  OccurrenceProvider,
  RecurringProvider,
  TaskProvider,
} from "@structured/store";
import { Main } from "@structured/views/Main";
import NotFound from "@structured/views/NotFound";
import SuccessPayment from "@structured/views/SuccessPayment/SuccessPayment";
import { createBrowserRouter } from "react-router-dom";

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <TaskProvider>
        <OccurrenceProvider>
          <RecurringProvider>
            <Main />
          </RecurringProvider>
        </OccurrenceProvider>
      </TaskProvider>
    ),
  },
  {
    path: "*",
    element: <NotFound />,
  },
  {
    path: "/success",
    element: <SuccessPayment />,
  }
]);