import Box from "@mui/material/Box";
import { getColor } from "@structured/utils/colors";
import React from "react";
import { HexColorPicker } from "react-colorful";

import styles from "./ColorSelector.module.scss";

const STATIC_COLORS = [
  "day",
  "night",
  "nature",
  "classic",
  // "dawn",
  // "sunshine",
  // "forest",
  // "twilight",
  // "midnight",
];

export interface ColorSelectorPaperProps {
  value: string;
  hexValue: string;
  onChange: (color: string) => void;
}

export const ColorSelectorPaper: React.FC<ColorSelectorPaperProps> = (
  props
) => (
  <Box
    sx={{
      borderRadius: "1rem",
    }}
    className={styles.ColorSelector}>
    <HexColorPicker
      style={{ cursor: "pointer" }}
      color={props.hexValue}
      onChange={props.onChange}
    />

    <Box className={styles.ColorSelector__Palettes}>
      {STATIC_COLORS.map((color) => (

        <Box
          key={color}
          component="span"
          onClick={() => props.onChange(color)}
          sx={{
            width: "2rem",
            height: "2rem",
            borderRadius: "50%",
            cursor: "pointer",
            backgroundColor: (theme) => getColor(color, theme.palette.mode),
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {props.value === color && (
            <Box
              sx={{
                width: "1.5rem",
                height: "1.5rem",
                borderRadius: "50%",
                border: (theme) => `0.125rem solid ${theme.palette.background.paper}`,
                backgroundColor: "transparent",
              }}
            />
          )}

        </Box>
      ))}
    </Box>
  </Box>
);

