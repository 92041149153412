import type { TaskColor } from "./colors.types";

export const LIGHT_COLOR_MAP: Record<TaskColor, string> = {
  day: "#F49F99",
  dawn: "#FF9E74",
  sunshine: "#EAAA00",
  nature: "#91BF6C",
  night: "#6185A8",
  forest: "#228564",
  twilight: "#974767",
  midnight: "#2C5073",
  classic: "#000000",
} as const;

export const DARK_COLOR_MAP: Record<TaskColor, string> = {
  day: "#F88E87",
  dawn: "#F27C49",
  sunshine: "#FFC630",
  nature: "#77B247",
  night: "#5E96CB",
  forest: "#07895D",
  twilight: "#D54449",
  midnight: "#0268CB",
  classic: "#FFFFFF",
} as const;
